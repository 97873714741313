import React from "react";

export const GPA = 3.95;

export const languages = [
  "Java",
  "Python",
  "Go",
  "C",
  "C++",
  "JavaScript",
  "Kotlin",
];

export const tools = [
  "Telegraf",
  "Chef",
  "GraphQL",
  "React",
  "Redis",
  "MBeans"
];

export const undergraduateCourses = [
  "Object-Oriented Design",
  "Artificial Intelligence",
  "Discrete Structures",
  "Logic & Computation",
  "Computer Systems",
  "Theory of Computation",
  "Networks & Distributed Systems",
  "Embedded Design & Enabling Robotics",
];
export const mathCourses = [
  "Calculus III",
  "Advanced Linear Algebra",
  "Differential Equations",
  "Probability & Statistics",
];

export const graduateCourses = [
  "Algorithms",
  "Principles of Programming Languages",
];

export const projects = [
  {title: "Voronoi Tesselations",
    content: <div><p>I was reading about pathfinding algorithms, and I heard about the concept of Voronoi diagrams. For fun, I implemented Fortune's Algorithm, which uses a binary search tree and priority queue to partition a plane into a set of regions equidistant to pre-specified points. It uses a 'sweeping line' to create parabolas that trace out the individual lines on the diagram.</p></div>,
    image: "voronoi.png",
    languages: ["Java"],
    link: "https://github.com/ebriannem/Voronoi",},
  {title: "FreeCell AI",
  content: <div><p> I experimented with using an AI to play FreeCell solitaire. We used Python to implement FreeCell itself, then began testing various searches and heuristics. We read various
  research papers to compare our searches and heuristics with others' then used what we learned to improve. </p></div>,
  image: "freecell.png",
  languages: ["Python"],
    link: "",},

  {title: "Gallery WebApp",
    content: <div><p>As a fun personal project & way to learn a bit more about databases and react, I've been working on a minimalist webapp for displaying arrangements of images and richly formatted text. The user can create layouts by rearranging and resizing components. The layout, text components, and image links for each section are kept in Firestore. </p></div>,
    image: "galleryloaf.jpg",
    languages: ["React", "JavaScript", "Firestore"],
    link: "https://github.com/ebriannem/galleryloaf",}
];

export const industryExperience = [
  {company: "Intuit",
    title: "Software Engineering Co-Op",
    dates: "May 2019 - December 2019 (Ongoing)",
    content: <div><p>At Intuit, I've had the opportunity to take a project from conception to production. Given the concept of my project, I researched various tools and compared them. Working on a Scrum team, I then helped implement it using the tools we'd chosen. Among other things, my work included writing plugins for Telegraf in Go, adding to existing industry code using Java, and writing a Chef cookbook in Ruby. Currently, I'm working on developing an API in Kotlin that connects to a DynamoDB database to collect data from a Slack command and display it to a React-based UI.</p></div>,
    languages: ["Java", "GoLang", "Ruby", "Kotlin"],
    tools: ["Telegraf", "Chef", "Wavefront", "MBeans", "GraphQL", "DynamoDB"],
  }
];
